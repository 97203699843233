export enum UserType {
	JOBSEEKER = "JS" as any,
	COMPANY = "CP" as any,
	STAFF = "SF" as any,
	ADMIN = "AD" as any,
	GUEST = "GS" as any,
}
export enum JobType {
	FULLTIME = "FT" as any,
	PARTTIME = "PT" as any,
	CONRACT = "CT" as any,
	INTERN = "IN" as any,
}
