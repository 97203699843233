import { createSlice } from "@reduxjs/toolkit";

const initialState: ThemeProps = { theme: "light" };
const themeSlice = createSlice({
	name: "theme",
	initialState: initialState,
	reducers: {
		light: (state) => {
			state.theme = "light";
		},
		dark: (state) => {
			state.theme = "dark";
		},
	},
});

interface ThemeProps {
	theme: "light" | "dark";
}
export const { light, dark } = themeSlice.actions;
export default themeSlice.reducer;
