import { createSlice } from "@reduxjs/toolkit";

const initialState: progressBarState = {
	pending: false,
	error: false,
	// isProgressBar: false,
};

export const progressBarSlice = createSlice({
	name: "progressBar",
	initialState: initialState,
	reducers: {
		httpPending: (state) => {
			state.error = false;
			state.pending = true;
		},
		httpFulfilled: (state, { payload }: hideProgressProps) => {
			state.pending = false;
			state.pending = false;

			state.error = payload?.error || state.error;
		},
		// hideProgress: (state) => {
		// 	state.error = false;
		// 	state.pending = false;
		// },
	},
});

interface hideProgressProps {
	payload: {
		error?: boolean;
	};
}

interface progressBarState {
	pending: boolean;
	error: boolean;
}

export const { httpPending, httpFulfilled } = progressBarSlice.actions;
export default progressBarSlice.reducer;
