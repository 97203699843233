import { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDevice } from "../redux/slice/deviceSlice";
import { RootState } from "../redux/store";

export function useWindowSize() {
	const dispatch = useDispatch();
	const { desktop } = useSelector((state: RootState) => state.device);
	const [size, setSize] = useState([window.innerWidth, window.innerHeight]);

	useLayoutEffect(() => {
		function updateSize() {
			setSize([window.innerWidth, window.innerHeight]);
		}
		window.addEventListener("resize", updateSize);
		return () => window.removeEventListener("resize", updateSize);
	}, []);

	useLayoutEffect(() => {
		if (size[0] < 1040 && desktop) {
			dispatch(setDevice({ isDesktop: false }));
		} else if (size[0] > 1040 && !desktop) {
			dispatch(setDevice({ isDesktop: true }));
		}
	}, [size, desktop, dispatch]);
	return size;
}
