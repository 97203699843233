import "./../styles/progress.scss";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";

export default function ProgressBar(props: progressBarProps) {
	return (
		<div id="progress-bar-wrap">
			<Progress {...props} />
		</div>
	);
}

function Progress({
	initialWidth = 0,
	updateDuration = 400,
	pauseWidth = 80,
	endWidth = 100,
	endTransition = 300,
}: progressBarProps) {
	const dispatch = useDispatch();
	const [progress, setProgress] = useState<number>(initialWidth);
	const { pending } = useSelector((state: RootState) => state.progressBar);

	useEffect(() => {
		let startInterval: any;
		let endInterval: any;

		if (pending) {
			startInterval = setInterval(() => {
				setProgress((oldProgress: any) => {
					if (oldProgress === pauseWidth) {
						clearInterval(startInterval);
					}
					return Math.min(
						oldProgress + Math.random() * 10,
						pauseWidth
					);
				});
			}, updateDuration);
		}

		if (!pending) {
			clearInterval(startInterval);
			endInterval = setInterval(() => {
				setProgress((old) => {
					if (old === 100) {
						clearInterval(endInterval);
						return 0;
					}
					return Math.min(old + 100, 100);
				});
			}, endTransition);
		}

		return () => {
			clearInterval(startInterval);
			clearInterval(endInterval);
		};
	}, [
		pending,
		dispatch,
		pauseWidth,
		endWidth,
		endTransition,
		updateDuration,
	]);

	return (
		<div
			id="progress-bar"
			style={{
				width: `${progress}%`,
				...(!pending
					? { transition: `width ${endTransition}ms ease` }
					: {}),
				...(progress === 0 ? { transition: `width 10ms ease` } : {}),
			}}
		/>
	);
}

interface progressBarProps {
	initialWidth?: number;
	pauseWidth?: number;
	endWidth?: number;
	endTransition?: number;
	updateDuration?: number;
}
