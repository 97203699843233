import { Modal } from "@mantine/core";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ConfirmDialog } from "../core/CustomComponent";
import { LOGIN, SIGNUP } from "../data/PageConfig";
import { jshttpPb } from "../utils/httpService";
import { Toast } from "./toast";

export default function EmailVerify() {
	const { id, token } = useParams();
	const navigate = useNavigate();
	const [opened, setOpened] = useState(false);

	useEffect(() => {
		(async () => {
			try {
				const res = jshttpPb.post("user/verify-email/", {
					id: id,
					token: token,
				});
				Toast("Email Verified Sucessfully");
				setOpened(true);
			} catch (err: any) {
				Toast("Token Expired or Invalid", "error");
			}
		})();
	}, [id, token]);

	return (
		<div>
			<Modal
				opened={opened}
				onClose={() => setOpened(false)}
				title={<p className="text dialog-success">Success</p>}
				centered
			>
				Your email has been <span>verified</span>. Please Procced to
				login.
				<div className="d-flex d-flex-fe confirm-dialog-wrap">
					<button
						className="color-random-3"
						onClick={() => {
							navigate(LOGIN.path);
						}}
					>
						Okay
					</button>
				</div>
			</Modal>
		</div>
	);
}
