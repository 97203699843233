import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { lazy, ReactNode, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoadingAnimation from "./../components/LoadingAnimation";
import { RootState } from "../redux/store";
import {
	ACCOUNT,
	APPLIED_JOB,
	CHANGE_PASSWORD,
	COMPANY,
	COMPANY_PROFILE,
	DASHBOARD,
	EDUCATION,
	EMPLOYMENT,
	FOLLOWED_COMPANY,
	HOME,
	INDEX,
	JOB,
	JOB_CATEGORY_CRUD,
	CITY_CRUD,
	JOB_REPORT,
	JOB_SKILL_CRUD,
	LOGIN,
	NOT_FOUND,
	PERSONAL_DETAILS,
	POSTED_JOBS,
	POST_JOB,
	PROFILE,
	REFERENCES,
	REGISTER_STAFF,
	RESET_PASSWORD,
	RESUME,
	SAVED_JOB,
	SEARCH_JOB,
	SIGNUP,
	SOCIAL_NETWORK,
	VERIFY_COMPANY,
} from "../data/PageConfig";

import { UserType } from "../data/enums";
import ResetPassword from "../pages/ResetPassword";
import PageNotFound from "../pages/PageNotFound";
import EmailVerify from "./EmailVerify";

// Lazy Import of Components

const Header = lazy(() => import("./../components/header"));
const Footer = lazy(() => import("./../components/footer"));
const Home = lazy(() => import("./../pages/Home"));
const Login = lazy(() => import("./../pages/Login"));
const Signup = lazy(() => import("./../pages/Signup"));
const LandingPage = lazy(() => import("./../pages/LandingPage"));
const Profile = lazy(() => import("./../pages/Profile"));
const Dashboard = lazy(() => import("../pages/Dashboard"));
const MyJobs = lazy(() => import("../pages/MyJobs"));
const JobDetail = lazy(() => import("../pages/JobDetail"));
const Resume = lazy(() => import("../pages/Resume"));
const CompanyDetail = lazy(() => import("../pages/CompanyDetail"));
const JobSearch = lazy(() => import("../pages/JobSearch"));

/*
 *
 * App Router
 *
 */
function SuspenseLoading() {
	return (
		<div
			className="d-flex d-flex-yc d-flex-xc"
			style={{
				position: "absolute",
				height: "100%",
				width: "100%",
			}}
		>
			<LoadingAnimation />
		</div>
	);
}

export function AppRouter() {
	return (
		<Suspense fallback={<SuspenseLoading />}>
			<Router>
				<Header />
				<div
					className="app-body"
					style={{ minHeight: window.innerHeight }}
				>
					<Routes>
						<Route
							path="*"
							element={<Navigate to={NOT_FOUND.path} />}
						/>
						<Route
							path="verify-email/:id/:token"
							element={<EmailVerify />}
						/>
						<Route
							path={NOT_FOUND.path}
							element={<PageNotFound />}
						/>
						<Route
							path={JOB.path}
							element={<Navigate to="1/" />}
						></Route>
						<Route path="job/:jobId/" element={<JobDetail />} />
						<Route
							path="job/:jobId/:title/"
							element={<JobDetail />}
						/>
						<Route
							path={COMPANY.path + ":id/"}
							element={<CompanyDetail />}
						/>
						<Route path={SEARCH_JOB.path} element={<JobSearch />} />

						<Route element={<PublicOnlyRoute />}>
							<Route
								path={INDEX.path}
								element={<LandingPage />}
							/>
							<Route path={LOGIN.path} element={<Login />} />
							<Route path={SIGNUP.path} element={<Signup />} />
							<Route
								path={RESET_PASSWORD.path}
								element={<ResetPassword />}
							/>
						</Route>
						<Route element={<PrivateRoute />}>
							{renderMultiple(
								[
									PROFILE.path,
									ACCOUNT.path,
									CHANGE_PASSWORD.path,
								],
								<Profile />
							)}
							<Route element={<DashboardRoute />}>
								<Route
									path={DASHBOARD.path}
									element={<Dashboard />}
								>
									<Route element={<VerifiedCompanyRoute />}>
										{renderMultiple(
											[
												POST_JOB.path,
												POSTED_JOBS.path,
												`${JOB_REPORT.path}:postId/`,
											],
											<Dashboard />
										)}
									</Route>

									<Route element={<StaffRoute />}>
										{renderMultiple(
											[
												VERIFY_COMPANY.path,
												JOB_CATEGORY_CRUD.path,
												JOB_SKILL_CRUD.path,
												CITY_CRUD.path,
											],
											<Dashboard />
										)}
									</Route>
									<Route element={<AdminRoute />}>
										{renderMultiple(
											[REGISTER_STAFF.path],
											<Dashboard />
										)}
									</Route>
								</Route>
								<Route
									path={COMPANY_PROFILE.path}
									element={<Profile />}
								/>
							</Route>

							{/* Job Seeker Route */}
							<Route element={<JobseekerRoute />}>
								<Route path={HOME.path} element={<Home />} />
								<Route
									path={RESUME.path}
									element={
										<Navigate to={PERSONAL_DETAILS.path} />
									}
								/>
								{renderMultiple(
									[
										PERSONAL_DETAILS.path,
										EDUCATION.path,
										EMPLOYMENT.path,
										SOCIAL_NETWORK.path,
										REFERENCES.path,
									],
									<Resume />
								)}
								{renderMultiple(
									[
										SAVED_JOB.path,
										APPLIED_JOB.path,
										FOLLOWED_COMPANY.path,
									],
									<MyJobs />
								)}
							</Route>
						</Route>
					</Routes>
				</div>
				<Footer />
			</Router>
		</Suspense>
	);
}

function renderMultiple(paths: string[], Element: ReactNode) {
	return (
		<>
			{paths.map((path) => (
				<Route path={path} key={path} element={Element} />
			))}
		</>
	);
}

export function PrivateRoute() {
	const { isLogin } = useSelector((state: RootState) => state.auth);
	return isLogin ? <Outlet /> : <Navigate to={INDEX.path} />;
}

export function PublicOnlyRoute() {
	const { isLogin } = useSelector((state: RootState) => state.auth);
	return isLogin ? <Navigate to={HOME.path} /> : <Outlet />;
}

export function JobseekerRoute() {
	const { type } = useSelector((state: RootState) => state.auth);
	return type === UserType.JOBSEEKER ? (
		<Outlet />
	) : (
		<Navigate to={DASHBOARD.path} />
	);
}

export function DashboardRoute() {
	const { type } = useSelector((state: RootState) => state.auth);

	return type === UserType.JOBSEEKER ? (
		<Navigate to={HOME.path} />
	) : (
		<Outlet />
	);
}
// export function VerifiedCompanyRoute() {
// 	const profile = useSelector((state: RootState) => state.user.profile);
// 	console.log(profile);
// 	return <Navigate to={COMPANY_PROFILE.path} />;

// 	// return profile?.is_verified === true &&
// 	// 	profile.user.type === UserType.COMPANY ? (
// 	// 	<Outlet />
// 	// ) : (
// 	// 	<Navigate to={COMPANY_PROFILE.path} />
// 	// );
// }

export function StaffRoute() {
	const { type } = useSelector((state: RootState) => state.auth);
	return type === UserType.STAFF ? <Outlet /> : <Navigate to={HOME.path} />;
}
export function AdminRoute() {
	const { type } = useSelector((state: RootState) => state.auth);
	return type === UserType.ADMIN ? <Outlet /> : <Navigate to={HOME.path} />;
}
export function VerifiedCompanyRoute() {
	const { type } = useSelector((state: RootState) => state.auth);
	const isVerified = useSelector(
		(state: RootState) => state.user.profile?.is_verified
	);

	return type === UserType.COMPANY && isVerified ? (
		<Outlet />
	) : (
		<Navigate to={HOME.path} />
	);
}

export type ParamProps = {
	jobId?: string;
};
