/*PAGE TIITLE*/
export const SITE = "Jobsies";

export const SUFFIX = "  ·  " + SITE;
export const INDEX = {
	name: "Home",
	title: "The Next Generation Recruitment System" + SUFFIX,
	path: "/",
};
export const LOGIN = {
	name: "Login",
	title: "Login" + SUFFIX,
	path: "/login/",
};
export const SIGNUP = {
	name: "Signup",
	title: "Signup" + SUFFIX,
	path: "/signup/",
};
export const RESET_PASSWORD = {
	name: "Reset Password",
	title: "Reset Password" + SUFFIX,
	path: "/reset/",
};
export const HOME = {
	name: "Home",
	title: "Home" + SUFFIX,
	path: "/home/",
};

export const CONTACT = {
	name: "Contact",
	title: "Contact us" + SUFFIX,
	path: "/contact/",
};
export const ABOUT = {
	name: "About",
	title: "About us" + SUFFIX,
	path: "/about/",
};
export const JOB = {
	name: "Job",
	title: "Job Vacancy" + SUFFIX,
	path: "/job/",
};
export const COMPANY = {
	name: "Company",
	title: "Company" + SUFFIX,
	path: "/company/",
};

export const PROFILE = {
	name: "Profile",
	title: "Profile" + SUFFIX,
	path: "/profile/",
};
export const ACCOUNT = {
	name: "Account",
	title: "Account" + PROFILE.title,
	path: PROFILE.path + "account/",
};
export const CHANGE_PASSWORD = {
	name: "Change Password",
	title: "Change Password" + PROFILE.title,
	path: PROFILE.path + "change-password/",
};
export const COMPANY_PROFILE = {
	name: "Company Profile",
	title: "Company Profile" + PROFILE.title,
	path: PROFILE.path + "company-profile/",
};
/*
 * Dashboard
 */
export const DASHBOARD = {
	name: "Dashboard",
	title: "Dashboard" + SUFFIX,
	path: "/dashboard/",
};
export const POST_JOB = {
	name: "Post Job",
	title: "Post Job" + SUFFIX,
	path: DASHBOARD.path + "job/post/",
};
export const POSTED_JOBS = {
	name: "Posted Jobs",
	title: "Posted Jobs" + SUFFIX,
	path: DASHBOARD.path + "job/history/",
};
export const JOB_REPORT = {
	name: "Job Report",
	title: "Job Report" + SUFFIX,
	path: DASHBOARD.path + "job/report/",
};
export const VERIFY_COMPANY = {
	name: "Company Verification",
	title: "Verify Company" + SUFFIX,
	path: DASHBOARD.path + "company/verification/",
};
export const JOB_CATEGORY_CRUD = {
	name: "Job Category",
	title: "Verify Company" + SUFFIX,
	path: DASHBOARD.path + "job/category/",
};
export const JOB_SKILL_CRUD = {
	name: "Job Skill",
	title: "Verify Company" + SUFFIX,
	path: DASHBOARD.path + "job/skill/",
};
export const CITY_CRUD = {
	name: "City",
	title: "Verify Company" + SUFFIX,
	path: DASHBOARD.path + "city/",
};
export const REGISTER_STAFF = {
	name: "Staff Register",
	title: "Staff Register" + SUFFIX,
	path: DASHBOARD.path + "signup/staff/",
};
export const NOT_FOUND = {
	name: "Not Found",
	title: "Page not Found" + SUFFIX,
	path: "/404",
};

/*
 * RESUME
 */
export const RESUME = {
	name: "Resume",
	title: "Resume" + SUFFIX,
	path: "/resume/",
};
export const PERSONAL_DETAILS = {
	name: "Personal Details",
	title: "Personal Details" + SUFFIX,
	path: RESUME.path + "personal/",
};
export const EDUCATION = {
	name: "Education History",
	title: "Education" + SUFFIX,
	path: RESUME.path + "education/",
};
export const EMPLOYMENT = {
	name: "Employment History",
	title: "Employment" + SUFFIX,
	path: RESUME.path + "employment/",
};
export const SOCIAL_NETWORK = {
	name: "Social Network",
	title: "Social Network" + SUFFIX,
	path: RESUME.path + "social-network/",
};
export const REFERENCES = {
	name: "References",
	title: "additional-information" + SUFFIX,
	path: RESUME.path + "references/",
};

export const APPLIED_JOB = {
	name: "Applied Jobs",
	title: "Applied Jobs" + SUFFIX,
	path: "/applied-job/",
};
export const FOLLOWED_COMPANY = {
	name: "Followed Companies",
	title: "Followed Company" + SUFFIX,
	path: "/followed-company/",
};
export const SAVED_JOB = {
	name: "Saved Jobs",
	title: "Saved Jobs" + SUFFIX,
	path: "/saved-job/",
};
export const SEARCH_JOB = {
	name: "Job Search",
	title: "Job Search" + SUFFIX,
	path: "job/search/",
};

export interface PageConfigProps {
	name: string;
	title: string;
	path: string;
}
/*
 * BUTTON TO BE DISPLAY ON HEADER WHEN USER NOT LOGINED
 */
export const publicHeaderButton = [INDEX, ABOUT, CONTACT];

/*
 * BUTTON TO BE DISPLAY ON HEADER WHEN a JOBSEEKER is LOGINED
 */
export const jobseekerHeaderButtons = [
	HOME,
	{ ...SAVED_JOB, name: "Jobs" },
	RESUME,
];

/*
 * BUTTON TO BE DISPLAY ON HEADER WHEN a COMPANY is LOGINED
 */
export const companyHeaderButtons = [
	DASHBOARD,
	{ ...COMPANY_PROFILE, name: "Profile" },
];
export const adminHeaderButtons = [
	DASHBOARD,
	{ ...PROFILE, name: "Profile" },
	{ ...VERIFY_COMPANY, name: "Company" },
];
