import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slice/userSlice";
import authReducer from "./slice/authSlice";
import progressBar from "./slice/progressBarSlice";
import device from "./slice/deviceSlice";
import themeSlice from "./slice/themeSlice";

export const store = configureStore({
	reducer: {
		device: device,
		progressBar: progressBar,
		user: userReducer,
		auth: authReducer,
		theme: themeSlice,
	},
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
