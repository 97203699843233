import "./styles/index.scss";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import CustomToast from "./components/toast";
import { injectStore } from "./utils/httpService";
import axios from "axios";
import { MantineProvider } from "@mantine/core";
// axios.defaults.baseURL = process.env.REACT_APP_API_PATH;
// axios.defaults.withCredentials = true;
// axios.defaults.headers.post["Content-Type"] = "application/json";

injectStore(store);

// axios.interceptors.response.use(

// 	function (error: AxiosError) {
// 		if (error.response?.status === 403) {
// 			store.dispatch(resetAuth());
// 		}
// 		store.dispatch(httpFulfilled({ error: true }));
// 		return Promise.reject(error);
// 	}
// );
ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<MantineProvider>
				<CustomToast />
				<App />
			</MantineProvider>
		</Provider>
	</React.StrictMode>,
	document.getElementById("root")
);

reportWebVitals();
