import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { API_AUTH } from "../../data/Endpoints";
import { UserType } from "../../data/enums";
import { jshttp, jshttpPb } from "../../utils/httpService";

export const resetAuth = createAction("auth/reset/");
// User Authenticate Thunk
export const userAuthenticate = createAsyncThunk("/user/auth/", async () => {
	const req = await jshttp.get(API_AUTH.path).then((req) => req.data);
	return req;
});

// User Login Thunk
export const userLogin = createAsyncThunk(
	"/user/login/",
	async (auth: LoginState, { rejectWithValue }) => {
		try {
			const req = await jshttpPb.post(API_AUTH.path, auth);
			return req.data;
		} catch (err: any) {
			return rejectWithValue(err.response?.data);
		}
	}
);

// User Signup Thunk
export const userSignup = createAsyncThunk(
	"/user/signup/",
	async (user: SignupState, { rejectWithValue }) => {
		try {
			const req = await jshttpPb.post("/user/jobseeker/", user);
			return req.data;
		} catch (err: any) {
			return rejectWithValue(err.response?.data);
		}
	}
);

export const companySignup = createAsyncThunk(
	"/user/signup/",
	async (user: SignupState, { rejectWithValue }) => {
		try {
			const req = await jshttpPb.post("/user/company/", user);
			return req.data;
		} catch (err: any) {
			return rejectWithValue(err.response?.data);
		}
	}
);

// User Logout Thunk
export const userLogout = createAsyncThunk("/user/logout", async () => {
	const req = await jshttpPb.delete(API_AUTH.path).then((req) => req.data);
	return req;
});

interface SignupState {
	user: {
		name: String;
		email: String;
		type: UserType;
		phone: string;
		password: String;
		passwordc: String;
	};
}

interface LoginState {
	email: string;
	password: string;
	key: string;
	isRemember: boolean;
}
