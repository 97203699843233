import React, { useEffect, useState } from "react";
import "../styles/authForm.scss";
import { LOGIN, RESET_PASSWORD, SIGNUP } from "../data/PageConfig";
import { useDispatch, useSelector } from "react-redux";
import { userLogin } from "../redux/thunk/authThunk";
import { Link } from "react-router-dom";
import { emailValidation } from "../utils/extras";
import { Toast } from "../components/toast";
import * as Yup from "yup";
import {
	Checkbox,
	ControllerError,
	SubmitButton,
	TextInput,
} from "../core/CustomComponent";
import ReCAPTCHA from "react-google-recaptcha";
import { RootState } from "../redux/store";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
// import { Checkbox} from "@mantine/core";
const SITE_KEY = process.env.REACT_APP_SITE_KEY;

const loginSchema = yup.object().shape({
	email: yup.string().required("Enter your Registered Email Address"),
});

export default function ResetPassword() {
	useEffect(() => {
		document.title = RESET_PASSWORD.title;
	}, []);

	const {
		register,
		formState: { errors, isSubmitting },
		handleSubmit,
	} = useForm<ResetPasswordProps>({
		mode: "onBlur",

		resolver: yupResolver(loginSchema),
	});

	console.log("LOGIN RENDER");

	const loginButton = async (data: ResetPasswordProps) => {
		console.log(data);
	};

	return (
		<div className="auth-form-wrap res-margin">
			{console.log("LOGIN RETURN RENDER")}
			<h1>{RESET_PASSWORD.name}</h1>
			{/* <p>We are happy to see you back!</p> */}

			<form className="auth-form" onSubmit={handleSubmit(loginButton)}>
				{/* Email or Username Field */}
				<TextInput
					label="Email"
					placeholder="Email"
					{...register("email")}
					error={errors.email?.message}
				/>
				<SubmitButton
					className="auth-button"
					style={{ margin: 0 }}
					type="submit"
					fetching={isSubmitting}
				>
					Send Verification Code
				</SubmitButton>
			</form>
			<div className="auth-form-extra">
				<Link to={LOGIN.path}>&nbsp;Procceed to Login</Link>
			</div>
		</div>
	);
}

interface ResetPasswordProps {
	email: string;
}
