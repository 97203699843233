import { useEffect } from "react";
import { Link } from "react-router-dom";
import { HOME, NOT_FOUND } from "../data/PageConfig";
import "./../styles/PageNotFound.scss";
export default function PageNotFound() {
	useEffect(() => {
		document.title = NOT_FOUND.title;
	});
	return (
		<div className="pnf-wrap d-flex d-flex-yc d-flex-xc">
			<div>
				<h1>Oops!</h1>
				<br />
				<h2>404 - PAGE NOT FOUND</h2>
				<br />
				<br />
				<br />
				<br />
				<div style={{ textAlign: "center" }}>
					<Link to={HOME.path}>GO TO HOMEPAGE</Link>
				</div>
			</div>
		</div>
	);
}
