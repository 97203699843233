import { createSlice } from "@reduxjs/toolkit";
import {
	resetAuth,
	userAuthenticate,
	userLogin,
	userLogout,
	userSignup,
} from "../thunk/authThunk";
import { UserType } from "../../data/enums";
import { Toast } from "../../components/toast";

const initialState: AuthState = {
	isLogin: false,
	pending: null,
	type: null,
};

export const authSlice = createSlice({
	name: "auth",
	initialState: initialState,
	reducers: {},
	extraReducers: (builder) => {
		// User Login Fulfill
		builder.addCase(userLogin.fulfilled, (state, { payload }) => {
			state.isLogin = true;
			state.type = payload.data.type;
			Toast(payload?.message || "Login Sucessfull", "success");
		});

		// User Login Rejected
		builder.addCase(userLogin.rejected, (state, { payload }: any) => {
			Toast(payload?.message || "Error while logging in", "error");
		});

		builder.addCase(userAuthenticate.pending, (state, { payload }) => {
			state.pending = true;
		});
		builder.addCase(userAuthenticate.fulfilled, (state, { payload }) => {
			state.isLogin = payload.data.isValid;
			state.type = payload.data.type;
			state.pending = false;
		});

		builder.addCase(
			userAuthenticate.rejected,
			(state, { payload }: any) => {
				state.pending = false;
			}
		);

		builder.addCase(userLogout.fulfilled, (state, { payload }) => {
			state.isLogin = false;
			state.type = null;

			Toast(payload?.message || "Sucessfully Loggedout", "success");
		});

		builder.addCase(userSignup.fulfilled, (state, { payload }) => {
			Toast(payload?.message || "Signup Sucessfull.", "success");
		});

		builder.addCase(userSignup.rejected, (state, { payload }: any) => {
			const value = payload.message.user;
			Toast(
				// <>
				// 	{Object.keys(value).map((key, index) => {
				// 		var err = "";
				// 		console.log(value[key]);

				// 		for (const x of value[key]) {
				// 			err += x;
				// 		}
				// 		return <p key={index}>{err}</p>;
				// 	})}
				// </>,
				value.email?.[0] || JSON.stringify(value),
				"error"
			);
			console.log(value);
		});
		//RESETING THE AUTH SLICE
		builder.addCase(resetAuth, (state) => {
			return initialState;
		});
	},
});

interface AuthState {
	isLogin: boolean;
	pending: boolean | null;
	type: UserType | null;
}

export default authSlice.reducer;
