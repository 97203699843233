import { createSlice } from "@reduxjs/toolkit";
import { Toast } from "../../components/toast";
import { UserProps } from "../../data/DataProps";
import { UserType } from "../../data/enums";
import { resetUser, userProfile } from "./../thunk/userThunk";

const initialState: UserState = {
	pending: true,
};

export const userSlice = createSlice({
	name: "user",
	initialState: initialState,
	reducers: {
		updateUser: (state, { payload }) => {
			return {
				...state,
				profile: {
					...state.profile,
					...payload,
					user: {
						...state.profile?.user,
						...payload?.user,
					},
				},
			};
		},
		setCvId: (state, { payload: { id } }) => {
			if (state.profile) {
				state.profile.cv_id = id;
			}
		},
		updateAvatar: (state, { payload: { url } }) => {
			if (state.profile) {
				state.profile.user.profile_image = url;
			}
		},
		updateCv: (state, { payload: { url } }) => {
			if (state.profile) {
				state.profile.external_cv = url;
			}
		},
	},
	extraReducers: (builder) => {
		// User Update Fulfill
		builder.addCase(userProfile.fulfilled, (state, { payload }) => {
			let data = {};
			if (payload.data.user.type !== UserType.COMPANY) {
				const names = payload.data.user.name?.split(" ");
				data = { fname: names[0] };

				if (names.length === 3) {
					data = { ...data, mname: names[1], lname: names[2] };
				} else {
					if (names.length === 2) {
						data = { ...data, lname: names[1] };
					}
				}
			}
			state.profile = {
				...payload.data,
				user: { ...payload.data.user, ...data },
			};
		});
		// User Update Rejected
		builder.addCase(userProfile.rejected, (state, { payload }) => {
			console.log("REJETC USER PROfiLE");
			state.pending = false;
		});
		//Clearing User Info
		builder.addCase(resetUser, () => {
			return initialState;
		});
	},
});

interface def {
	pending: boolean;
	// error: boolean;
}

export interface UserState extends def {
	profile?: {
		user: UserProps;
		id?: number;
		is_cv_completed?: boolean;
		cover_image?: String;
		is_verified?: boolean;
		cv_id?: number | undefined;
		external_cv?: string;
	};
}

export const { updateUser, setCvId, updateAvatar, updateCv } =
	userSlice.actions;
export default userSlice.reducer;
