import { useDispatch, useSelector } from "react-redux";

import { AppRouter } from "./components/authRouter";
import LoadingAnimation from "./components/LoadingAnimation";

import { userAuthenticate } from "./redux/thunk/authThunk";
import { userProfile } from "./redux/thunk/userThunk";
import { RootState } from "./redux/store";

import ProgressBar from "./components/progress";
import Nothing from "./components/nothing";
import { useEffect } from "react";
import { dark } from "./redux/slice/themeSlice";

/**
 *
 * @returns
 */
export default function App() {
	console.log("APP NEW RENDER");
	const dispatch = useDispatch();
	const auth = useSelector((state: RootState) => state.auth);

	useEffect(() => {
		if (localStorage.getItem("theme") === "dark") {
			dispatch(dark());
			document.documentElement.setAttribute("data-theme", "dark");
		}

		dispatch(userAuthenticate());
		console.log("APP - USEFFECT for AUTHENTICATE USER");
	}, [dispatch]);

	useEffect(() => {
		if (auth.isLogin) {
			dispatch(userProfile());
			console.log("APP - USEFFECT for LOGIN CHECK USER FETCH");
		}
	}, [auth, dispatch]);

	if (auth.pending || auth.pending === null) {
		return <LoadingAnimation style={{ height: window.innerHeight }} />;
	}

	return (
		<div className="App" style={{ minHeight: window.innerHeight }}>
			<Nothing />
			<ProgressBar />
			<AppRouter />
		</div>
	);
}
