export const API_PROFILE = {
	path: "/user/profile/",
};
export const API_COMPANY_DETAIL = {
	path: "/user/company/",
};
export const API_STAFF_DETAILS = {
	path: "/user/staff/",
};
export const API_POST = {
	path: "/post/post/",
};

export const API_AUTH = {
	path: "/user/auth/",
};

export const API_POST_SAVE = {
	path: "/post/save/",
};
export const API_POST_APPLY = {
	path: "/post/apply/",
};
export const API_CV = {
	path: "cv/cv/",
};
export const API_EMPLOYMENT = {
	path: "cv/employment/",
};
export const API_EDUCATION = {
	path: "cv/education/",
};
export const API_SOCIAL_MEDIA = {
	path: "cv/social/",
};
export const API_REFERENCE = {
	path: "cv/reference/",
};

export const API_COMPANY_POSTS = {
	path: "post/company-posts/",
};
export const API_COMPANY_POST = {
	path: "post/company-post/",
};
export const API_COMPANY_PROFILE = {
	path: "user/company/profile/",
};

export const API_AVATAR = {
	path: "user/avatar/",
};

export const API_CV_UPLOAD = {
	path: "user/cv/",
};

export const API_CHANGE_PASSWORD = {
	path: "user/credential/",
};
export const API_USER_UPDATE = {
	path: "user/account/",
};
export const API_APPLIED_JOB = {
	path: "jobseeker/applied-job/",
};
export const API_SAVED_JOB = {
	path: "jobseeker/saved-job/",
};
export const API_FOLLOWED_COMPANY = {
	path: "user/company-follow/",
};
export const API_VERIFY_COMPANY = {
	path: "user/company-verify/",
};
export const API_JOB_CATEGORY = {
	path: "job/category/",
};
export const API_JOB_SKILL = {
	path: "job/skill/",
};
export const API_JOB_CITY = {
	path: "job/city/",
};
export const API_JOB_RECOMENDATION = {
	path: "job/recomendation/",
};
export const API_JOB_RECENT = {
	path: "job/recent/",
};
export const API_JOB_SEARCH = {
	path: "post/search?",
};
