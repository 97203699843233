import axios, { AxiosError } from "axios";
import { httpFulfilled, httpPending } from "../redux/slice/progressBarSlice";
import { resetAuth } from "../redux/thunk/authThunk";

let store: any;

export const injectStore = (_store: any) => {
	store = _store;
};
export const jshttpPb = axios.create({
	baseURL: process.env.REACT_APP_API_PATH,
	withCredentials: true,
	headers: { "Content-Type": "application/json" },
});
jshttpPb.interceptors.request.use(
	function (config) {
		store.dispatch(httpPending());
		return config;
	},
	function (error) {
		return Promise.reject(error);
	}
);

jshttpPb.interceptors.response.use(
	async function (response) {
		await store.dispatch(httpFulfilled({ error: false }));
		return response;
	},
	function (error: AxiosError) {
		if (error.response?.status === 401) {
			store.dispatch(resetAuth());
		}
		store.dispatch(httpFulfilled({ error: true }));
		return Promise.reject(error);
	}
);

export const jshttp = axios.create({
	baseURL: process.env.REACT_APP_API_PATH,
	withCredentials: true,
	headers: { "Content-Type": "application/json" },
});
