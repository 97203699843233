import "./../styles/toast.scss";
import {
	cssTransition,
	toast,
	ToastContainer,
	ToastContainerProps,
	ToastContent,
	ToastOptions,
	TypeOptions,
} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
/*
 * Custom Toast Container
 */
export default function CustomToast(props: ToastContainerProps) {
	return (
		<ToastContainer
			position="top-center"
			autoClose={3000}
			hideProgressBar
			// newestOnTop={false}
			// closeOnClick
			// rtl={false}
			// pauseOnFocusLoss
			// draggable
			closeButton={true}
			// pauseOnHover
			// icon={false}
			transition={cssTransition({
				enter: "toast-in",
				exit: "toast-out",
			})}
			limit={3}
			{...props}
		/>
	);
}

export function Toast(
	content: ToastContent,
	type: TypeOptions = "success",
	options?: ToastOptions<{}> | undefined
) {
	return toast(
		content,
		(options = {
			// icon: <img src={sucessIcon} alt="s" />,
			type: type,
			style: { borderLeft: `5px solid var(--toastify-color-${type})` },
			...options,
		})
	);
}

// type toastType=
