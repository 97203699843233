import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_PROFILE } from "../../data/Endpoints";
import { jshttpPb } from "./../../utils/httpService";

export const userProfile = createAsyncThunk(
	API_PROFILE.path,
	async (obj, { signal }) => {
		const source = axios.CancelToken.source();
		signal.addEventListener("abort", () => {
			source.cancel();
		});
		return jshttpPb
			.get("/user/profile", { cancelToken: source.token })
			.then((res) => res.data);
	}
);

export const resetUser = createAction("/user/reset/");
