import { CSSProperties } from "react";
import "./../styles/LoadingAnimation.css";

const LoadingAnimation = ({ style }: { style?: CSSProperties | undefined }) => {
	return (
		<div className="loading-animation" style={style}>
			<div className="lds-ellipsis">
				<div />
				<div />
				<div />
				<div />
			</div>
		</div>
	);
};
export default LoadingAnimation;
