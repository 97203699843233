import "./customComponent.scss";
import { FieldValues, UseFormRegister } from "react-hook-form";
import { forwardRef, ReactNode, useState } from "react";
import { Loader, LoaderProps, Menu, Modal, ModalProps } from "@mantine/core";
import { AiFillCloseCircle, AiFillCloseSquare } from "react-icons/ai";
import { RiCloseFill, RiCloseLine } from "react-icons/ri";
import { IoIosClose, IoMdClose, IoMdCloseCircleOutline } from "react-icons/io";
import { TiTick } from "react-icons/ti";
import { BiBookContent } from "react-icons/bi";

export const TextInput = forwardRef(
	(
		{ label, className, hideNumberArrow, error, ...props }: TextInputProps,
		ref: any
	) => {
		// const inputClass = {
		// 	...(error ? { borderBottom: "2px solid red" } : {}),
		// };
		const inputClass = `textfield__input ${className}${
			hideNumberArrow ? " input__hideArrow" : ""
		}`;

		return (
			<div className="textfield__container">
				{label && (
					<p className="tf-label">
						{label}
						{props.required && (
							<span style={{ color: "red", paddingLeft: 4 }}>
								*
							</span>
						)}
					</p>
				)}
				<div className="d-flex d-flex-yc">
					<input
						ref={ref}
						className={inputClass}
						// style={compStyle}
						{...props}
					/>
				</div>
				<p className="tf-error">{error ? error : "\u00A0"}</p>
			</div>
		);
	}
);

export const TextArea = forwardRef(
	({ label, error, className, ...props }: TextAreaProps, ref: any) => {
		const textareaClass = `textfield__input textfield__textarea ${className}`;
		return (
			<div className="textfield__container">
				{label && (
					<p className="tf-label">
						{label}
						{props.required && (
							<span style={{ color: "red", paddingLeft: 4 }}>
								*
							</span>
						)}
					</p>
				)}
				<textarea className={textareaClass} ref={ref} {...props} />
				<p className="tf-error">{error ? error : "\u00A0"}</p>
			</div>
		);
	}
);

export const Checkbox = forwardRef(
	({ label, error, className, ...props }: TextInputProps, ref: any) => {
		return (
			<div className="Checkbox__container d-flex d-flex-yc">
				<input
					id="CustomCheckbox"
					className="Checkbox__checkbox"
					type="checkbox"
					ref={ref}
					{...props}
				/>
				<label htmlFor="CustomCheckbox" className="cb-label">
					<span>
						<svg width="12px" height="9px" viewBox="0 0 12 10">
							<polyline points="1.5 6 4.5 9 10.5 1"></polyline>
						</svg>
					</span>
					<span>{label}</span>
				</label>
			</div>
		);
	}
);

export function ControllerError({
	children,
	error,
}: {
	children: ReactNode;
	error: string | undefined;
}) {
	return (
		<div>
			{children}
			<p className="tf-error">{error ? error : "\u00A0"}</p>
		</div>
	);
}
export function SubmitButton({
	children,
	fetching,
	text,
	className,
	rightAlign = true,
	raw = false,
	...props
}: SubmitButtonProps) {
	return (
		<div
			style={{
				textAlign: `${rightAlign ? "right" : "left"}`,
				marginTop: 20,
			}}
		>
			<button
				className={`${!raw && "submit-button"} ${
					className && className
				}`}
				disabled={fetching}
				{...props}
			>
				{fetching ? (
					<Loader color="var(--color-text-button)" variant="dots" />
				) : (
					children
				)}
			</button>
		</div>
	);
}
export function ClickButton({
	children,
	className,
	onClick,
	...props
}: React.ButtonHTMLAttributes<HTMLButtonElement>) {
	const [loading, setLoading] = useState(false);

	async function btnClick(
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) {
		if (onClick) {
			setLoading(true);
			await onClick(e);
			setLoading(false);
		}
	}
	return (
		<SubmitButton
			className={`submit-button ${className && className}`}
			{...props}
			fetching={loading}
			onClick={btnClick}
		>
			{loading ? (
				<Loader color="var(--color-text-button)" variant="dots" />
			) : (
				children
			)}
		</SubmitButton>
	);
}

export function SaveRemoveWrapper({
	children,
	closeClick,
	saveClick,
	closeButtonOutside = false,
	showSaveIcon = false,
	className,
	...props
}: SaveRemoveWrapperProps) {
	return (
		<div
			className={`close-icon-container ${className && className}`}
			{...props}
		>
			<div>{children}</div>
			<button
				className={`close-icon-wrap btn-${
					closeButtonOutside ? "out" : "in"
				}`}
				onClick={closeClick}
			>
				<IoMdClose size={30} color="var(--color-text)" />
			</button>
			{showSaveIcon && (
				<button className="save-icon-wrap" onClick={saveClick}>
					<TiTick size={28} color="var(--color-text)" />
				</button>
			)}
		</div>
	);
}

export function NoContentFound({
	message,
	withBg = true,
	noIcon = false,
}: {
	message?: ReactNode;
	withBg?: boolean;
	noIcon?: boolean;
}) {
	return (
		<div className={`d-flex d-flex-yc d-flex-xc ${withBg && "bg-primary"}`}>
			<div
				style={{
					textAlign: "center",
					padding: "4vw 0",
					color: "var(--color-text-primary)",
				}}
			>
				{!noIcon && <BiBookContent size={"8vh"} />}
				<h3>{message || "Nothing has been added yet."}</h3>
			</div>
		</div>
	);
}

export function TextDisplay({
	label,
	desc,
}: {
	label?: string;
	desc?: ReactNode;
}) {
	return (
		<div style={{ padding: "5px 0" }}>
			{label && <p className="tf-label">{label}</p>}
			{desc && (
				<p className="text-desc" style={{ padding: "0px 5px" }}>
					{desc}
				</p>
			)}
		</div>
	);
}
export function ConfirmDialog({
	children,
	opened,
	okButton,
	title = "Success",
	withoutCancel = false,
	...props
}: ConfirmDialogProps) {
	const [isOpened, setIsOpened] = useState(opened);
	function closeDialog() {
		setIsOpened(false);
	}
	async function okButtonClick(
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) {
		if (okButton?.onClick) await okButton?.onClick(e);
		await closeDialog();
	}
	console.log(opened);
	return (
		<>
			<Modal
				opened={isOpened}
				{...props}
				onClose={closeDialog}
				title={<p className="text dialog-success">{title}</p>}
			>
				{children}
				<div className="d-flex d-flex-fe confirm-dialog-wrap">
					{!withoutCancel && (
						<button
							className="color-random-2"
							onClick={closeDialog}
						>
							Cancel
						</button>
					)}
					<button className="color-random-3" onClick={okButtonClick}>
						{okButton?.name || "Okay"}
					</button>
				</div>
			</Modal>
		</>
	);
}
interface ConfirmDialogProps extends ModalProps {
	okButton?: {
		name?: string;
		onClick?: React.MouseEventHandler<HTMLButtonElement>;
	};
	withoutCancel?: boolean;
}
interface TextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
	label?: ReactNode;
	error?: string;
	hideNumberArrow?: boolean;
	register?: UseFormRegister<FieldValues>;
}
interface TextAreaProps
	extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
	label?: string;
	error?: string;
	register?: UseFormRegister<FieldValues>;
}

export interface SubmitButtonProps
	extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	fetching?: boolean;
	text?: String;
	raw?: boolean;
	rightAlign?: boolean;
}
interface SaveRemoveWrapperProps extends React.HTMLAttributes<HTMLDivElement> {
	children: ReactNode;
	closeClick?: React.MouseEventHandler<HTMLButtonElement>;
	saveClick?: React.MouseEventHandler<HTMLButtonElement>;
	showSaveIcon?: boolean;
	closeButtonOutside?: boolean;
}
