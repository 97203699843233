import { createSlice } from "@reduxjs/toolkit";

const initialState: DeviceState = {
	desktop: true,
};

export const deviceSlice = createSlice({
	name: "device",
	initialState: initialState,
	reducers: {
		setDevice: (
			state,
			{ payload }: { payload: { isDesktop: boolean } }
		) => {
			state.desktop = payload.isDesktop;
		},
	},
});

export interface DeviceState {
	desktop: boolean;
}

export const { setDevice } = deviceSlice.actions;
export default deviceSlice.reducer;
